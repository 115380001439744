<template>
  <f7-app :params="f7params" >




  


  <!-- Your main view, should have "view-main" class -->
  <f7-view main :masterDetailBreakpoint="960" class="safe-areas" url="/"></f7-view>


   

   <f7-login-screen :opened="loginAbierto" id="my-login-screen">
    <f7-view>
      <f7-page login-screen>
        <f7-login-screen-title>CoronaChile Panel</f7-login-screen-title>
          <f7-block><f7-button @click="entraConGoogle()" outline raised>Login con Google</f7-button></f7-block>
        <f7-list>
          <f7-block-footer>
            Coronachile.com<br>Powered by HICAPPS
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
  </f7-app>
</template>
<script>

  import routes from '../js/routes.js';
import {mapState,mapGetters} from 'vuex';

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'mAPPer', // App name
          theme: 'md', // Automatic theme detection



          // App routes
          routes: routes,
        },
          loginAbierto: true,
      }
    },
    methods: {
      cargaInicial(){
        console.log('loading data');
      },
      entraConGoogle(){
       let provider = new this.$firebase.auth.GoogleAuthProvider();
         this.$f7.dialog.preloader('Navegando a Auth...');
        this.$firebase.auth().signInWithRedirect(provider);
     },
       logout(conexplicacion=false) {
        console.log('Logging out');
        this.$f7.dialog.preloader('Cerrando sesión...');
        this.$firebase.auth().signOut().then(()=> {
          console.log('Sign-out successful.');
          this.$store.commit('setObjTo',{what:'misprivx',to:{}});
           this.$store.commit('setObjTo',{what:'profile_userInfo',to:{}});
          this.$f7.dialog.close();
          if(conexplicacion){
            this.$f7.dialog.alert('No disponible para usuarios anonimos');
          }
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('Sign-out error.', error);
        });
      },
    },
    mounted() {
      this.$f7ready((f7) => {

        this.$f7.dialog.preloader('Cargando...');
        // Call F7 APIs here
        this.$firebase.auth().onAuthStateChanged((user)=> {
        if (user) {
          // User is signed in.
         // console.log('USUARIO DETECTADO POR OBSERVABLE');
         //  console.log('welcome',user);
            let profile_userInfo={
              displayName: user.displayName,
              email: user.email,
               emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              phoneNumber: user.phoneNumber,
              uid: user.uid,
              isAnonymous: user.isAnonymous
            };
            this.$store.commit('setObjTo',{what:'profile_userInfo',to:profile_userInfo});
            if(user.isAnonymous){
              this.$f7.dialog.close();
             this.logout(true);
            }
            else{
              let cleanemail=user.email.replace(/\./g,'^');
              this.$firebase.database().ref('ccpanel/'+cleanemail).once('value').then(snpanshot=>{
                if(!snpanshot.exists()){
                   this.$f7.dialog.close();
                    this.logout(true);
                }
                else{
                   this.$store.commit('mergeObjTo',{what:'misprivx',to:snpanshot.val()||{}});
                  this.$f7.dialog.close();
                  this.loginAbierto=false;
                   this.cargaInicial();
                }
              }).catch(error=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,error.code,()=>{
                   this.logout(true);
                });
              });
            }
        } else {
          // User is signed out.
          this.$store.commit('setObjTo',{what:'profile_userInfo',to:{}});
          console.log('no user logged in');
          this.loginAbierto=true;
          this.$f7.dialog.close();
        }
      });
      });
    }
  }
</script>