import Vuex from 'vuex'
import Vue from 'vue'
import moment from 'moment';
moment.locale('es');
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appName:'mapper',
        appUrl: 'https://mapper.coronachile.com',
        appVersion: '0.1.8',
        activeTab:'/',
        profile_userInfo: {},
        misprivx:{},
        instix:{},
        mappedInstitux:{},
        allDeclaredInstituiones:{},
        gmapsAPIkey: "AIzaSyCFr9gvCBaKd-7VbRiljFT-w7W4_Wl2lUw",
        //validar de aqui a abajo
        mainCoreData:[],
        fechafiltro:'rango',
        rangoDates: null,
        byday: null
    },
    getters: {
        myAvatar: state => {
                return (state.profile_userInfo && state.profile_userInfo.photoURL) ? state.profile_userInfo.photoURL : state.profile_defaultAvatar;
        },
        myName: state=>{
            return state.profile_userInfo.displayName || 'Indique su nombre';
        },
    },
    mutations: {
  
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        mergeObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},state[payload.what],payload.to);
        },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        pushToArray: (state,payload) => {
            state[payload.array].push(payload.value);
        },
        conactToArray: (state,payload) => {
          let concatenado = state[payload.array].concat(payload.value);
          state[payload.array] = concatenado;
      },
        removeFromArray: (state,payload)=>{
            let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
            if(indiexist!=-1){
                state[payload.array].splice(indiexist,1);
            }
        },
        updateMyAvatar: (state,avatarURL)=>{
            state.profile_userInfo.photoURL=avatarURL;
        },
        //Editar array
        editArray(state, payload){
            const index = state[payload.array].findIndex((item) => item[payload.attr] === payload.value);
            if(index !== -1){
                state[payload.array].splice(index, 1, payload.updatedValue);
            }
        },
        updateObjectPropValue:(state,payload)=>{
            if(payload.neovalue==null){
                let clonado=Object.assign({},state[payload.what]);
                delete clonado[payload.prop];
                state[payload.what]=Object.assign({},clonado);
            }
            else{
                if(!state[payload.what][payload.prop]){
                    let clonado=Object.assign({},state[payload.what]);
                    clonado[payload.prop]=payload.neovalue;
                    state[payload.what]=Object.assign({},clonado);
                }
                else{
                    if(typeof payload.neovalue === 'object'){
                        state[payload.what][payload.prop]=Object.assign({},payload.neovalue);
                    }
                    else{
                        state[payload.what][payload.prop]=payload.neovalue;
                    }
                }
            }
        }
    },
    actions:{

    }
})