<template>
  <f7-page name="mapeme">
    <f7-navbar title="Mapeo a Instituciones">
      <f7-nav-right v-if="loadingPlace"><f7-block><f7-preloader></f7-preloader></f7-block></f7-nav-right>
    </f7-navbar>


  <f7-row>
    <f7-col>
     
 <f7-list class="no-margin-top" media-list>
    <f7-list-item divider title="Institución a mapear"></f7-list-item>
         <f7-list-item 
         :header="instix.comuna+'-'+instix.provincia"
          :title="instix.institucion"
          :subtitle="instix.region"
          :text="instix.unidad+'|'+instix.fono"
          :footer="instix.direccion"
        >
        <f7-icon slot="after" :material="instix.mapid=='0'?'help_outline':'check'" v-if="instix.mapid"></f7-icon>
        </f7-list-item>
 </f7-list>
 <f7-block>
   <f7-button @click="nowMap('0',true)">No mapeable</f7-button>
 </f7-block>
    </f7-col>
    <f7-col>
  <f7-searchbar
        search-container=".search-list-existing"
        search-in=".item-title"
        not-found-el=".noencontrado"
        :remove-diacritics="true"
        :backdrop="false"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
       <f7-list class="searchbar-not-found noencontrado">
    <f7-list-button @click="createnew=true" title="No encontrado. Crear nuevo y mapear"></f7-list-button>
  </f7-list>

   <f7-list 
      ref="realmappedinsti" 
      class="search-list-existing"
      media-list
       virtual-list
      :virtual-list-params="{ items, searchAll ,renderExternal, height: 66}"
      >
      <ul>
        <f7-list-item 
          v-for="(oneinst,idx) in vlData.items"
          :key="idx"
          radio
          :value="oneinst.place_id" 
          name="mysintx-radio"
         :header="oneinst.formatted_address"
          :title="oneinst.freename"
           :checked="instix.mapid === oneinst.place_id"
          @change="nowMap($event.target.value,true)"
          :style="`top: ${vlData.topPosition}px`"
        ></f7-list-item>
      </ul>
      </f7-list>
    </f7-col>
  </f7-row>




 <f7-popup class="createnew-popup" :opened="createnew" @popup:closed="createnew = false">
      <f7-page>
        <f7-navbar title="Crear nueva institución">
          <f7-nav-right>
            <f7-link popup-close>Cancelar</f7-link>
          </f7-nav-right>
        </f7-navbar>
            <f7-list class="no-margin-top" media-list>
    <f7-list-item divider title="Institución a mapear"></f7-list-item>
         <f7-list-item 
         :header="instix.comuna+'-'+instix.provincia"
          :title="instix.institucion"
          :subtitle="instix.region"
          :text="instix.fono"
          :footer="instix.direccion"
        ></f7-list-item>
        <f7-list-input
  
          label="Address/Place"
          type="text"
          input-id="autocomplete_clp"
          placeholder="Direccion o nombre de institucion"
          clear-button
        >
        </f7-list-input>
   <div class="mapresource"></div>
      </f7-list>
           <template v-if="resource.ginfo.place_id">
        <f7-block>
         <f7-button outline @click="createAndMap()">Crear y mapear</f7-button>
        </f7-block>
      <f7-list  no-hairlines-md>
        <f7-list-input
          label="Nombre Institución"
          type="text"
          :value="resource.freename"
         @input="resource.freename = $event.target.value"
          placeholder="Nombre de institucion"
          clear-button
        >
        </f7-list-input>
        <f7-list-item
        header="Nombre Google Maps"
        :title="resource.ginfo.name"
        >
        </f7-list-item>
        <f7-list-item
        header="Dirección"
        :title="resource.ginfo.formatted_address"
        >
        </f7-list-item>
        <f7-list-item
        v-for="(address_component,idx) in resource.ginfo.address_components" :key="idx"
        :header="address_component.types[0]"
        :title="address_component.short_name"
        >
        </f7-list-item>
          </f7-list>
      </template>
      </f7-page>
    </f7-popup>




  </f7-page>
</template>

<script>
import {mapState} from 'vuex';
import loadGoogleMapsApi from 'load-google-maps-api';

export default {

  data(){
    return {
       vlData: {
          items: [],
        },
        resource:{
          ginfo:{},
          freename: ''
        },
        lemapa: null,
        googleMaps: null,
        placemarker: null,
        createnew: false,
        loadingPlace: false
    }
  },
  computed:{
      ...mapState(['mappedInstitux','instix']),
       items(){
     return Object.values(this.mappedInstitux).sort((a,b)=>{
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
   },
  },
   watch:{
     items(newval){
      this.$refs.realmappedinsti.f7VirtualList.replaceAllItems(newval);
    },
    instix(newoval){
      this.resource.ginfo={};
      this.resource.freename=newoval.institucion || '';
      document.getElementById('autocomplete_clp').value = '';
    }
  },
  mounted(){
    this.resource.freename=this.instix.institucion || '';
    this.dibujarMpa();
  },
  methods:{
    createAndMap(){
      console.log('creando', this.resource);
      //generar ID,
      let tosave=Object.assign({},this.resource.ginfo);
      tosave.freename=this.resource.freename;
      let updates = {};
      updates['/mapped/' + tosave.place_id] = tosave;
      this.$f7.dialog.preloader('Guardando...');
      return this.$firebase.database().ref().update(updates).then(()=>{
        console.log('guardado');
        this.$store.commit('updateObjectPropValue',{what:'mappedInstitux',prop: tosave.place_id,neovalue:tosave});
        this.nowMap(tosave.place_id,false);
        this.createnew=false;
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log('save error',error);
      });
    },
    nowMap(placeID,preloader=false){
      console.log('mapeando a ', placeID);
      //generar ID,
      if(preloader){
         this.$f7.dialog.preloader('Guardando...');
      }
      let updates = {};
      updates['/privx/' + this.instix.userid + '/inst/' + this.instix.id+'/mapid'] = placeID;
      return this.$firebase.database().ref().update(updates).then(()=>{
        console.log('guardado');
        let clonada=Object.assign({},this.instix,{mapid:placeID});
        this.$store.commit('updateObjectPropValue',{what:'allDeclaredInstituiones',prop: this.instix.id,neovalue:clonada});
        this.$store.commit('setObjTo',{what:'instix',to:clonada});
        this.$f7.dialog.close();
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log('save error',error);
      });
    },
          dibujarMpa(){
         loadGoogleMapsApi({key:this.$store.state.gmapsAPIkey,libraries:['places']}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
          this.lemapa= new this.googleMaps.Map(document.querySelector('.mapresource'), {
             center: {lat: -33.4724228, lng:-70.7699151},
              zoom: 9,
              streetViewControl: false,
              mapTypeControl: false
            });
            var countryRestrict = {'country': 'cl'};
           // var hostnameRegexp = new RegExp('^https?://.+?/');
            let autocomplete = new this.googleMaps.places.Autocomplete(
            /** @type {!HTMLInputElement} */ (
                document.getElementById('autocomplete_clp')), {
              componentRestrictions: countryRestrict,
              fields: ['address_components','formatted_address','international_phone_number','name','place_id','website','url','geometry.location']
            });
            autocomplete.addListener('place_changed', ()=> {
            this.loadingPlace=true;
            var place = autocomplete.getPlace();
            console.log(place);
               this.loadingPlace=false;
            if (place.geometry) {
              this.lemapa.panTo(place.geometry.location);
              this.lemapa.setZoom(15);
              console.log('selected place is',place);
     
              let tostore={
                address_components: place.address_components || [],
                formatted_address: place.formatted_address || '',
                international_phone_number: place.international_phone_number || '',
                lat: place.geometry.location.lat() || null,
                lng: place.geometry.location.lng() || null,
                name:place.name || '',
                place_id:place.place_id ,
                website:place.website || '',
                url:place.url || ''
              };
              this.resource.ginfo=Object.assign({},this.resource.ginfo,tostore);
              //crear marker
               this.placemarker=new this.googleMaps.Marker({
                position: place.geometry.location,
                 map: this.lemapa
              });
            } else {
              document.getElementById('autocomplete_clp').placeholder = 'Enter a valid place';
            }
          });

          }).catch((error)=> {
            console.error(error);
          });
      },
 
        searchAll(query, items) {
        const found = [];
        for (let i = 0; i < items.length; i += 1) {
          let prefrename=items[i].freename || '';
          let freename=this.$f7.utils.removeDiacritics(prefrename);
           let lename=items[i].name || '';
          let luaname=this.$f7.utils.removeDiacritics(lename);
          if (
            freename && freename.toLowerCase().indexOf(query.toLowerCase()) >= 0 
            || luaname && luaname.toLowerCase().indexOf(query.toLowerCase()) >= 0 
          || query.trim() === '') found.push(i);
        }
        return found; // return array with mathced indexes
      },
    renderExternal(vl, vlData) {
        this.vlData = vlData;
      },
  }
}
</script>
<style>
div.pac-container.pac-logo {
  display: inline-block; 
    z-index: 1000000 !important;
}
.mapresource{
  height:300px;
}
</style>