<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-title :title="'mAPPer '+$store.state.appVersion" :subtitle="items.length+' pendientes'" ></f7-nav-title>
       <f7-nav-right>
         <f7-toggle @toggle:change="filterex" ></f7-toggle>
      <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
    </f7-nav-right>
     <f7-searchbar
      class="searchbar-demo"
      expandable
      search-container=".padfix"
      :remove-diacritics="true"
      search-item="li"
      placeholder="Buscador"
      search-in=".item-title"
    ></f7-searchbar>
    </f7-navbar>

    <f7-list class="searchbar-not-found">
    <f7-list-item title="Nothing found"></f7-list-item>
  </f7-list>

   <f7-list 
      ref="hospitalsinsti" 
      class="padfix no-margin-top"
      media-list
       virtual-list
      :virtual-list-params="{ items, searchAll ,renderExternal, height: 66}"
      >
      <ul>
        <f7-list-item 
          v-for="(oneinst,idx) in vlData.items"
          :key="idx"
         :header="oneinst.comuna+'-'+oneinst.provincia"
          :title="oneinst.institucion+'('+oneinst.cuantos+')'"
          :style="`top: ${vlData.topPosition}px`"
          :class="{activia:instix.id==oneinst.id}"
          link="#"
          @click="mapethisone(oneinst)"
        >
         <f7-icon slot="after" :material="oneinst.mapid=='0'?'help_outline':'check'" v-if="oneinst.mapid"></f7-icon>
         </f7-list-item>
      </ul>
      </f7-list>
  </f7-page>
</template>

<script>
import {mapState} from 'vuex';
export default {
  data(){
    return{
        vlData: {
          items: [],
        },
        onlyPending:false,
    }
  },
  created(){

  },
  mounted(){
    this.loadObservers();
  },
  computed:{
        ...mapState(['instix','allDeclaredInstituiones']),
     items(){
      let elarreglos=Object.values(this.allDeclaredInstituiones) || [];
      let filtrados = elarreglos.filter(word =>{
       return !this.onlyPending || (this.onlyPending && !word.mapid);
     });

      //onlyPending
     return filtrados.sort((a,b)=>{
            var x = a.cuantos;
            var y = b.cuantos;
            if (x < y) {return 1;}
            if (x > y) {return -1;}
            return 0;
        });
   },
  },
  watch:{
     items(newval){
      this.$refs.hospitalsinsti.f7VirtualList.replaceAllItems(newval);
    }
  },
  methods:{
    loadObservers(){
      let reference = this.$firebase.database().ref('privx');

      reference.on('child_added', (data)=> {
        let userid=data.key;
        let unerinf=data.val();
        this.updateListIem(userid,unerinf);
      });

      reference.on('child_changed', (data)=> {
          let userid=data.key;
          let unerinf=data.val();
          this.updateListIem(userid,unerinf);
      });

      let referencemapped = this.$firebase.database().ref('mapped');

      referencemapped.on('child_added', (data)=> {
        let placeid=data.key;
        let unerinf=data.val();
        this.updateMapped(placeid,unerinf);
      });

      referencemapped.on('child_changed', (data)=> {
          let placeid=data.key;
          let unerinf=data.val();
          this.updateMapped(placeid,unerinf);
      });
    },
    updateMapped(placeid,sumeta){
      console.log('nuevo mapeo');
      this.$store.commit('updateObjectPropValue',{what:'mappedInstitux',prop: placeid,neovalue:sumeta});
    },
    updateListIem(uerid,sumeta){
       console.log('nuevo mapeo');
          let instits=sumeta.inst || {};
          let elN=sumeta.forms || {};
          let cuantos=Object.keys(elN) || [];
          let institsinfo=Object.values(instits) || [];
          institsinfo.forEach(unainstitux => {
            if(unainstitux.id && unainstitux.institucion){
              unainstitux.userid=uerid;
              unainstitux.cuantos=cuantos.length;
              if(!unainstitux.mapid){
                unainstitux.mapid=null;
              }
              this.$store.commit('updateObjectPropValue',{what:'allDeclaredInstituiones',prop: unainstitux.id,neovalue:unainstitux});
            }
          });
    },
    filterex(event){
      console.log(event);
      this.onlyPending=event;
    },
    mapethisone(instix){
      //instix
      this.$store.commit('setObjTo',{what:'instix',to:instix});
      console.log(instix);
      this.$f7.views.main.router.navigate('/mapme/');

    },
     searchAll(query, items) {
        const found = [];
        for (let i = 0; i < items.length; i += 1) {
          if (
            items[i].institucion && items[i].institucion.toLowerCase().indexOf(query.toLowerCase()) >= 0 
            || items[i].comuna && items[i].comuna.toLowerCase().indexOf(query.toLowerCase()) >= 0 
          || query.trim() === '') found.push(i);
        }
        return found; // return array with mathced indexes
      },
    renderExternal(vl, vlData) {
        this.vlData = vlData;
      }
  }
}
</script>
<style>
.activia{
  border-left: 4px solid #007aff;
}

</style>